.top-margin {
  margin-top: 65px;
  height: 30px;
  background: rgb(18, 18, 75);
  background: #03203b;
  border-bottom: 0.2px solid white;
}

.open-side-bar,
.close-side-bar {
  transition: width 0.5s ease; /* Adjust the duration and timing function as needed */
  min-height: 40rem;
  /* background: rgb(249, 249, 249);
  background: rgba(0, 0, 0, 0.1);
  background: rgb(18, 18, 75); */
  background: #03203b;
  padding: 10px;
}

.open-side-bar {
  width: 250px;

  & .img-span {
    display: flex;
    height: 4rem;
    align-items: center;
    justify-content: center;
  }

  & .arrow-span {
    float: right;
  }

  & .prof-heading {
    & span {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      cursor: pointer;
      padding: 1px 10px;
      border-bottom: 1.2px solid rgb(0, 0, 0, 0.1);
      margin-top: 15px;

      &:last-child {
        border-bottom: unset;
      }

      &:hover {
        background-image: linear-gradient(to left, rgba(8, 2, 1, 0.1), transparent);
      }

      & p {
        padding: unset;
        margin-top: 0%;
        font-size: 90%;
        color: rgb(255, 254, 254);

        &:hover {
          background-image: unset;
        }
      }

      & i {
        width: 30px;
        color: rgb(255, 254, 254);
      }
    }
  }
}

.close-side-bar {
  width: 80px;

  & .img-span {
    display: none;
    height: 0rem;
  }

  & .arrow-span {
    float: unset;
    position: relative;
    margin-left: 60px;
  }

  & .slide-prof-heading {
    display: flex;
    flex-direction: column;
    gap: 30px;

    & span {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-bottom: unset;

      &:hover {
        background-image: linear-gradient(to left, rgba(8, 2, 1, 0.1), transparent);
      }

      & p {
        padding: unset;
        margin-top: 0%;
        font-size: 90%;
        color: rgb(255, 254, 254);

        &:hover {
          background-image: unset;
        }
      }

      & i {
        font-size: 150%;
        color: white;
      }
    }
  }
}

.prof-icon-top {
  & p {
    cursor: pointer;

    & i {
      padding-right: 10px;
      color: white;
    }
  }
}
