/* @import url('https://fonts.googleapis.com/css?family=Montserrat:400,800'); */


  
  .heading {
    font-weight: bold;
    margin: 0;
  }
  .heading-awi{
    font-weight: bold;
    margin: 0;
    margin-top: 15px;
    color: #FFFFFF;
  }
  .message {
    font-size: 14px;
    font-weight: 100;
    line-height: 20px;
    letter-spacing: 0.5px;
    margin: 20px 0 30px;
  }
  
  .both-btn {
    border-radius: 20px;
    border: 1px solid rgb(0, 57, 126);
    background-color: rgb(0, 57, 126);
    color: #FFFFFF;
    font-size: 12px;
    font-weight: bold;
    padding: 12px 45px;
    letter-spacing: 1px;
    text-transform: uppercase;
    transition: transform 80ms ease-in;
  }
  /* Add these styles to your CSS file */
.login-buttons-container {
  display: flex;
  justify-content: space-around; /* Adjust as needed */
  margin-bottom: 20px; /* Add margin to separate buttons from form */
}

.employee-sign-in-button.active,
.admin-sign-in-button.active {
  border: 1px solid #3498db; /* Add your preferred border style and color */
  background-color: #3498db;
  color: greenyellow;
  font-size: 12px;
  font-weight: bolder;
}

  .both-btn:active {
    transform: scale(0.95);
  }
  
  .both-btn:focus {
    outline: none;
  }
  
  .both-btn.ghost {
    border-color: #FFFFFF;

  }
  .ghost{
    transition: transform 80ms ease-in;
  }
  .ghost:hover{

    transform: scale(1.05); 
  }
  .form-container {
    background-color: #f8f5f5;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 50px;
    height: 100%;
    color: #092748;
    text-align: center;
    width: 100%;
    z-index: 2;
  }
  
  .input-field {
    background-color: #eee;
    border: none;
    padding: 12px 15px;
    margin: 8px 0;
    width: 100%;
  }
  
  .button-container {
    display: flex;
    margin-top: 100px;
    justify-content: space-between;
    border:2px solid #f1eaea; 
    padding: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25),
    0 10px 10px rgba(0, 0, 0, 0.22);
    position: relative;
    /* margin-left: 20%; */
    background-color: #f8f5f5;
  }

  .employee-sign-in-button,
  .admin-sign-in-button {
    border-radius: 20px;
    /* border: 0.5px solid rgb(0, 57, 126);
    background-color: rgb(0, 57, 126); */
    /* color: #FFFFFF; 
    background-color:#3498db;*/
    border: 0.5px solid #f8f5f5;
    background-color: #f8f5f5;
    color: rgb(169, 166, 166);
    font-size: 12px;
    width: 50%;
    font-weight: lighter;
    letter-spacing: 1px;
    text-transform: uppercase;
    transition: transform 80ms ease-in;
     /* margin-left: 14%; */
  }
  .admin-sign-in-button {
    margin-right:-0.35%;
  }

  .employee-sign-in-button:hover,
  .admin-sign-in-button:hover {
    cursor: pointer;
    transform: scale(1.02);
  }
  
  .main-container {
    /* margin-top: 20px; */
    /* border-radius: 10px; */
    background-color: #f1eaea;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25),
      0 10px 10px rgba(0, 0, 0, 0.22);
    position: relative;
    overflow: hidden;
    width: 868px;
    max-width: 100%;
    min-height: 480px;
  }
  
  .form-container {
    position: absolute;
    top: 0;
    height: 100%;
    transition: all 0.6s ease-in-out;
  }
  
  .sign-in-container {
    left: 0;
    width: 50%;
    z-index: 2;
  }
  
  #container.right-panel-active .sign-in-container {
    transform: translateX(100%);
  }
  
  .sign-up-container {
    left: 0;
    width: 50%;
    opacity: 0;
    z-index: 1;
  }
  
  #container.right-panel-active .sign-up-container {
    transform: translateX(100%);
    opacity: 1;
    z-index: 5;
    animation: show 0.6s;
  }
  
  @keyframes show {
    0%, 49.99% {
      opacity: 0;
      z-index: 1;
    }
  
    50%, 100% {
      opacity: 1;
      z-index: 5;
    }
  }
  
  .overlay-container {
    position: absolute;
    top: 0;
    left: 50%;
    width: 50%;
    height: 100%;
    overflow: hidden;
    transition: transform 0.6s ease-in-out;
    /* z-index: 100; */
  }
  
  #container.right-panel-active .overlay-container {
    transform: translateX(-100%);
  }
  
  .overlay {
    background: #3498db;
    background: -webkit-linear-gradient(to right,);
    background: linear-gradient(to right, #092748);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 0;
    color: #FFFFFF;
    position: relative;
    left: -100%;
    height: 100%;
    width: 200%;
    transform: translateX(0);
    transition: transform 0.6s ease-in-out;
  }
  
  #container.right-panel-active .overlay {
    transform: translateX(50%);
  }
  
  .overlay-panel {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 40px;
    text-align: center;
    top: 0;
    height: 100%;
    width: 50%;
    transform: translateX(0);
    transition: transform 0.6s ease-in-out;
  }
  
  .overlay-left {
    transform: translateX(-20%);
  }
  
  #container.right-panel-active .overlay-left {
    transform: translateX(0);
  }
  
  .overlay-right {
    right: 0;
    transform: translateX(0);
  }
  
  #container.right-panel-active .overlay-right {
    transform: translateX(20%);
  }
  