.container.contact__container{
    width: 58%;
    display: grid;
    grid-template-columns: 30% 58%;
    gap:12%;
}
.contact__options{
    display: flex;
    flex-direction: column;
    gap:1.2rem;
}
#contact{
   background-color: rgb(253, 251, 251);
    padding: 50px;
}
.contact__option{
    background:#092144 ;
    padding: 1.2rem;
    border-radius: 1.2rem;
    text-align: center;
    border: 2px solid rgb(0, 106, 133);
    transition:var(--transition);

}
.contact__option:hover{
    background: var(--color-white);
 
}

.hello{
    color: white;
    font-family: 'Montserrat', sans-serif;
}
.contact__option-icon{
    font-size: 1.8rem;
    margin-bottom: 0.5rem;
    color: var(--color-white);
}
.contact__option:hover .contact__option-icon{
    color: var(--color-primary);
}
form{
    display: flex;
    flex-direction: column;
    gap: 1.2rem;

}

input,textarea{
  
    padding: 1.5rem;
    border-radius: 0.5rem;
    /* background: transparent; */
    border: 2px solid rgb(0, 106, 133);
    /* resize: none; */
    color :var(--color-dark);
    
    

}
.filin{
  width: 100%;
}
.error-message{
    color: red;
    font-size: small;
}
select{
    /* width: 100%;
    /* padding: 1.5rem; */
    /* border-radius: 0.5rem; */ 
    /* background: transparent; */
    border: 2px solid rgb(0, 106, 133);
    /* resize: none; */
    /* color :var(--color-dark); */
}
.btn.btn-light{
    background-color: rgb(9, 33, 68);
    color: whitesmoke;
}
.btn.btn-light:hover{
    background-color: rgb(2, 53, 129);
    color: white;
}


@media screen and (max-width:1024px) {
    .container.contact__container{
        grid-template-columns: 1fr;
        gap: 2rem;
        
    }
}
@media screen and (max-width:600px) {
    .container.contact__container{
        grid-template-columns: 1fr;
        width: var(--container-width-md);

    }
}