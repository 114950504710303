.signup-form {
    max-width: 600px;
    min-height: 400px;
    margin: 5% auto 0;
    box-shadow: 0px 0.5px 50px lightgrey;
    padding: 15px;
    border-radius: 7px;
    & form {
      gap: 5px;
    }
  }
  .steps-progress {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .step-container {
    position: relative;
  }
  .step {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    text-align: center;
    line-height: 50px;
    background-color: #f2f2f2;
    border: 2px solid #ccc;
    margin: 0 15px;
    position: relative;
    cursor: pointer;
  }
  .step.active {
    background-color: #007bff;
    color: #fff;
  }
  .connector {
    /* position: absolute; */
    /* top: 50%; */
    left: calc(50% + 26px); /* half width of step + 1px */
    height: 2px;
    background-color: #f2f2f2;
  }
  .connector::after {
    content: '';
    /* position: absolute; */
    top: -4px;
    left: -4px;
    width: 8px;
    height: 8px;
    background-color: #007bff;
    border-radius: 50%;
  }
  .connector.full {
    width: calc(70% - 48px); /* full width of container - 2*half width of step */
    left: calc(50% + 24px); /* half width of step */
    margin-top: 25px;
  }
  

  .connector.filled {
    background-color: #007bff;
  }

  .prev-next-btn {
    display: flex;
    justify-content: space-between;
    & button {
      background: #ccc;
      border-radius: 25px;
    }
  }