/* fullcard css start */
#fullcard{
  display: flex;
  text-align: center;
  width: 100%;
  justify-content: space-around;
  
}
.task-card button.done {
  background-color: #4caf50;
  color: #fff;
}
.done {
  background-color: #4caf50;
  color: #fff;
}
/* UesrDashboard.css */

.task-card-page {
  /* Add some styling to the container */
}
.task-cards-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* gap: 20px; */
  overflow-x: scroll;
}
.buttoncont{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.popup111 {
  position: fixed;
  
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border: 1px solid #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  max-width: 80%;
  max-height: 80%;
  overflow: auto;
}

.task-card {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: grid;
  grid-template-rows: auto auto auto auto;
  gap: 10px;
}

.task-card h3 {
  margin-bottom: 10px;
}

.buttoncont button {
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin: 5px;
}


.tabs {
  display: flex;
  margin-bottom: 20px;
  border: #092144 2px solid;
}

button {
  background-color: #fff; /* Set the default background color */
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  margin-right: 10px;
  outline: none;
  transition: background-color 0.3s ease; /* Add a transition effect */
}

button.active {
  border-bottom: #53ff28 2px solid; /* Set the background color for the active button */
  color: #71d613; /* Set the text color for the active button */
}

.line {
  height: 2px;
  width: 0;
  background-color: #3498db; /* Set the line color */
  transition: width 0.3s ease; /* Add a transition effect */
}

/* Add additional styles as needed */

/* Add this to your CSS file or style block */

.task-details-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
  max-width: 600px;
  background-color: white;
  border: 1px solid #ccc;
  padding: 20px;
  overflow-y: auto;
  z-index: 9999;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

.popup-content {
  /* Add any additional styling for the popup content */
}

.task-details-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9998;
}

.task-description {
  text-align: justify;
  line-height: 1.5;
  white-space: pre-wrap;
}
.full-screen-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  z-index: 300000;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.full-screen-image-container {
  max-width: 80%;
  max-height: 80%;
}

.full-screen-image {
  max-width: 100%;
  max-height: 100%;
}


.task-card button.not-done {
  background-color: #f44336;
  color: #fff;
}
.not-done {
  background-color: #f44336;
  color: #fff;
}
.profileContent{
  text-align: start;
  padding-left:5%;
  margin-top: 45px;
}
#profilecard1{
  display: flex;
  border: 1px solid rgb(239, 166, 58);
}


/* .logoutbtn{
 position: absolute;
 right: 3%;
 margin-top: 15px;
 font-size: 18px;
} */

.text-muted{
  font-size: 18px;
  color: #616060;
}

.profileInput{
  display: flex;
  margin-left: 15%;
}

.input2{
  margin-left: 10px;
}
.profile{
  display: flex;
}

#Card2001{
  position: relative;
  background-color: aliceblue;
  border: 1px solid aliceblue;
}

.card-body{
  display: flex;
  flex-direction: column;
  
}
/* fullcard css end */

/* EmployeeTable.css */
/* Basic table styling */



.employee-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  border: 1px solid #ccc;
  
}

.employee-table th, .employee-table td {
  border: 1px solid #ccc;
  padding: 10px;
  text-align: left;
 /* Add an ellipsis for long cell content */
}
.employee-table th {
  white-space: nowrap; /* Prevent line breaks within headings */
  overflow: hidden; /* Hide overflowed text */
  text-overflow: ellipsis; /* Add an ellipsis for long headings */
}

.employee-table th {
  background-color: #092144;
  color: #fff;
}

.employee-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

/* Style the Document cell */
.document-cell {
  font-size: 50%;
  vertical-align: middle;
}

/* Add more styles as needed */

 
 .employee-table tbody tr:nth-child(even) {
   background-color: #f2f2f2;
 }
 
 .employee-table tbody tr:hover {
   background-color: #e0e0e0;
 }
 .download-button {
   background-color: #288537; /* Button background color */
   color: #fff; /* Button text color */
   border: none; /* Remove any borders */
   padding: 5px 10px; /* Adjust padding as needed */
   cursor: pointer;
   border-radius: 20px;
   height: 30px;
   margin-top: 50px;
   /* Add any other styling as needed */
 }
 .filters {
   display: flex;
   flex-wrap: wrap;
   justify-content: space-between;
   align-items: center;
   gap: 10px;
   margin-bottom: 20px;
 }
 
 .filters input {
   padding: 10px;
   border: 1px solid #ccc;
   border-radius: 5px;
 }
 
 /* Style for the employee name */
 .employee-name {
   font-size: 24px;
   font-weight: bold;
   margin-right: 20px;
 }
 /* CSS for Button Styles */

.buttons-container {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.buttons-container button {
  background-color: #092748;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.buttons-container button:hover {
  background-color: #2980b9;
}

.buttons-container button.active {
  background-color: #e74c3c;
}


