.fullscreen-modal{
    top: 30%;
}
.modl{
    display: flex;
    justify-content: center;
}
/* BackgroundAnimation.css */
.background-animation {
    /* Add your animation styles here */
    width: 100%;
    height: 100vh;
    background: linear-gradient(to bottom, #3498db, #2c3e50);
    /* Add any other styles you want for the background animation */
  }
  