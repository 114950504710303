

.maincard {
    background-color: #f8f9fa;
    padding: 20px;
  }
  
  .dashboard-container {
    display: flex;
    flex-direction: column;
    
  }
   
  .profileCardcont  {
    /* width: 95vw; */
    padding: 20px;
    border: 1px double rgb(42, 159, 228);
    background-color: #d4caf6;
    border-radius: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: row;
  justify-content: space-around;
  margin-right: 20px;
  }
  
  
  
  .img1{
    width: 20vw;
    margin-right: 20px;
    border-radius: 50%;
  }  
  .card1d {
    background-color: #c7b9ee;
    padding: 2px;
    width: 100%;
    height: 220px;
  }
  .card-flash{
    width:100%;
  margin: 10px 0px;
  padding: 2px;
  border: 2px solid rgb(241, 226, 86);
  background-color: rgb(234, 235, 169);
  border-radius: 5px;
  font-size: small;
  }
  .small-card{
    display: flex;
    flex-direction: column;
  justify-content: space-around;
  align-items: center;
  text-align:center;
   width: 100px;
   height: 75px;
  /* background-color: burlywood; */
  background-color: transparent;
  border: 1px solid grey;
  margin: 10px;
  padding: 3px;
  border-radius: 15px;
  } .but1{
    color:"green";
    margin-right: 10px;
  }
  /* YourComponent.css */
  
  .custom-table {
    width: 100%;
    margin: 2px;
    border-collapse: collapse;
  }
  
  .custom-table th,
  .custom-table td {
    border: 2px solid #ddd; /* Add your preferred border color */
    padding: 8px;
    text-align: left;
  }
  
  .custom-table th {
    background-color: #f2f2f2; /* Add your preferred header background color */
  }
  
  
  /*Table content.css */
  .table-container {
    position: relative;
    border-collapse: separate;
    border-spacing: 0 10px;
    margin-top: 20px;
    /* border-radius: 5px; */
    box-shadow: 0 0px 8px rgb(104, 113, 235);
  }
  
  .custom-table1 {
    width: 20%;
    border-collapse: collapse;
    /* box-shadow: 0 4px 8px rgba(240, 13, 13, 0.973); */
  }
  .custom-table1 th,
  .custom-table1 td {
    border: 1px solid #ddd; /* Add your preferred border color */
    padding: 18px;
    text-align: left;
   box-shadow: 0px 0px 28px rgba(198, 215, 235, 0.973);
  
  
  }
  
  .custom-table1 th {
    background-color: #f2f2f2; /* Add your preferred header background color */
  
  }
  
  
  .popButton, .popbutton, .profile-i {
    margin-right: 10px;
  }
  
  .profile-i svg {
    width: 16px;
    height: 16px;
    fill: currentColor;
  }
  
  .popButton, .popbutton, .profile-i {
    margin-right: 10px;
  }
  
  .popButton {
    padding: 15px;
    text-align: left;
    cursor: pointer;
    color: #3498db;
  }
  
  /* .popbutton {
    color: #e74c3c;
  } */
  
  .popButton:hover {
    background-color: #f2f2f2;
  }
  
  .popButton.opened {
    background-color: #3498db;
    color: #fff;
  }
  
  