#slide23 {
  margin-top: 30px;
  display: flex;
  width: 100%;

}
.item23 {
  width: 180px;
  height: 300px;
  background-position: 50% 50%;
  background-size: cover;
  transform: translate(0, 5%);
  border-radius: 5px;
}
.item23:nth-child(1){
  display: none;
}
.item23:nth-child(2) {
  left: 0;
  transform: translate(0, 5%);
  border-radius: 20px;
  width: 60%;
  box-shadow: none;
  margin-right: 20px;
}
.item23:nth-child(3) {
  left: 60%;
  margin-right: 10px;
  filter: brightness(50%);
  border-radius: 20px;
}
.item23:nth-child(4) {
  left: calc(60% + 200px);
  margin-right: 10px;
  filter: brightness(50%);
  border-radius: 20px;

}
.item23:nth-child(5) {
  left: calc(60% + 450px);
  filter: brightness(50%);
  border-radius: 20px;

}
.item23:last-child {
  left: calc(80% + 1050px);
  margin-right: 20px;
  display: none;
  filter: brightness(50%);

  opacity: 0;
}



#prev{
  float: left;
  margin-left: 10px;
}
#next{
  float: right;
}

.buttons123 {
  position: relative;
  bottom: 30px;
  width: 100%;
}
.buttons123 button {
  width: 40px;
  height: 40px;
  color: white;
  border-radius: 50%;
  border: 2px solid #ffffff;
  /* background:linear-gradient(270deg,#0f1c54,#2b224c); */
}

.buttons123-icon {
  margin-left: -5px;
}
.gallery-container23 {
  display: flex;
  flex-wrap: wrap;
}

.card23 {
  width: 200px;
  margin: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
}

.card23 img {
  width: 100%;
  height: auto;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.description23 {
  padding: 10px;
}

.popup23 {
  position: fixed;
  top: 40;
  left: 10;
  width: 80%;
  height: 80%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: scroll;
}

.popup-content23 {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
}

.close-btn23 {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 24px;
}



/* ----------------------- New Gallery Section --------------------------------------  */

.image-card {
  width: 100%;
  background: white;
  box-shadow: 0px 1px 4px rgb(247, 247, 247);
  margin: 10px  0;  
  cursor: pointer;  
  &:hover {
    /* transform: translateY(0) scale(1.01, 1.02); */
    & .img-desc {
      opacity: 1;
    }
  }
}
.image-card-img {
  width: 100%;
  object-fit: cover;
}
.description23 {
  position: relative;
  height: 0;
}
.img-desc {
  background: rgba(0, 0, 0, 0.7);
  font-family: "Bebas Neue", sans-serif;
  opacity: 0;
  transition: opacity 2500ms;
  color: #fff;
  margin-top: 10px;
}


.pop-img-cont:hover {
  & .img-desc {
    opacity: 1;
  }
}


.slick-next {
  margin-right: 1px;
}

/* .slick-prev {
  margin-top: 255px;
  margin-left: 30px;
  background: black;
  padding: 0;
  border-radius: 50%;
  &:hover {
    background-color: black;
  }
}
.slick-next {
  margin-top: 255px;
  margin-right: 30px;
  background: black;
  padding: 0;
  border-radius: 50%;
  &:hover {
    background-color: black;
  }
} */