*{
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  list-style: none;
  text-decoration: none;
  box-sizing: border-box;
  user-select: none;
  
}
::-webkit-scrollbar{
  display: none;
}

:root{
  --primary-hue: 200; /* Modified primary hue */
  --black-lightness: 10%; /* Adjusted black lightness */
  --dark-lightness: 25%; /* Adjusted dark lightness */
  --light-lightness: 85%; /* Adjusted light lightness */
  --white-lightness: 95%; /* Adjusted white lightness */
  --color-primary: hsl(var(--primary-hue), 72%, 57%);
  --color-white: hsl(60, 80%, var(--white-lightness));
  --color-light: hsl(var(--primary-hue), 80%, var(--light-lightness));
  --color-black: hsl(200, 80%, var(--black-lightness));
  --color-dark: hsl(200, 80%, var(--dark-lightness));

  --container-width-lg:76%;
  --container-width-md:90%;

  --border-radius-1:1.2rem;
  --border-radius-2:0.8rem;
  --border-radius-3:0.5rem;

  --transition: all 500ms ease ;

}
html{
  scroll-behavior: smooth;
}

body{
  font-family: 'Montserrat', sans-serif;
  line-height: 1.6;
  font-size: 0.9rem;
}

.container{
  width: var(--container-width-lg);
  margin-inline: auto;
  max-width: 1920px;
}
section{
  padding: 10rem 0;
  background: var(--color-light);
  transition: var(--transition);

  -webkit-transition: var(--transition);
  -moz-transition: var(--transition);
  -ms-transition: var(--transition);
  -o-transition: var(--transition);
}

section:nth-child(even){
  background:var(--color-white);
}

section>h2{
  text-align: center;
}
section>p{
  text-align: center;
  margin: 0.5rem auto 4rem;
  width: 40%;

}


a{
  color:var(--color-white);
  transition: var(--transition);
  -webkit-transition: var(--transition);
  -moz-transition: var(--transition);
  -ms-transition: var(--transition);
  -o-transition: var(--transition);
}

h1,h2,h3,h4,h5,h6{
  line-height: 1.1;
  color: var(--color-black);
}

h1{
  font-size: 3rem;
}
h2{
  font-size: 2rem;
}
h3{   
  font-size: 1.6rem;
}
h4{
  font-size: 1.1rem;
}
h5{
  font-size: 0.95rem;
}
h6{
  font-size: 0.8rem;
}


.img{
  display: block;
  width: 100%;
  object-fit: cover;
}

.btn{
  display: inline-block;
  width:fit-content;
  display: flex;
  align-items: center;
  gap: 1rem;
  background: var(--color-light);
  color:var(--color-black);
  padding: 0.6rem 2.5rem;
  border: 2px solid transparent;
  border-radius: var(--border-radius-2);
  -webkit-border-radius: var(--border-radius-2);
  -moz-border-radius: var(--border-radius-2);
  -ms-border-radius: var(--border-radius-2);
  -o-border-radius: var(--border-radius-2);
  cursor: pointer;
}

.btn:hover{
  background-color: transparent;
  border-color: var(--color-light);
  transform: translateY(-0.5rem);
  -webkit-transform: translateY(-0.5rem);
  -moz-transform: translateY(-0.5rem);
  -ms-transform: translateY(-0.5rem);
  -o-transform: translateY(-0.5rem);
}
.btn.white{
  background: var(--color-light);

}

.btn.white:hover{
  background: transparent;
  border-color: var(--color-white);
}

.btn.primary{
  background: var(--color-primary);
  color: rgb(255, 255, 255);
  
}
.btn.present{
  background: linear-gradient(90deg, rgba(175,51,97,1) 17%, rgba(179,132,35,1) 37%, rgba(190,22,150,1) 69%, rgba(32,23,208,1) 100%);
  color: rgb(255, 255, 255);
  
}
.btn.primary:hover{
  background: transparent;
  border-color: var(--color-primary);
  color:var(--color-primary);

}
.btn.sm{
  padding: 0.5rem 1.2rem;

}

@media screen and(max-width:1024px) {
  body{
      font-size: 1rem;
  }
  .container{
      width: var(--container-width-md);
  }

  section{
      padding: 8rem 0;
  }
  section>p{
      width: 72%;
      margin-bottom: 3rem;
  }
  h1{
      font-size: 2rem;
  }
}

@media screen and (max-width:600px) {
  h1{
      font-size: 2.4rem;
  }
  h2{
      font-size: 1.8rem;
  }
  h3{
      font-size: 1.4rem;
  }
  h4{
      font-size: 1rem;
  }
  .btn{
      padding: 0.6rem 1.5rem;
  }
  section>p{
      width: 72%;
      margin-bottom: 3rem;
  }

}