    .leave-form textarea {
    width: 100%;
    }
    
 .leaveform-buttons {
    display: flex;
    margin-top: -30px;
    & button {
        width: 50%;
        color: white;
    }
 }
