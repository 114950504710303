nav{
    width: 100vw;
    height: 4rem;
    display: grid;
    place-items: center;
    background-color: whitesmoke;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9;
    box-shadow: 0 0.1rem 0.25rem hsl(var(--primary-hue),68%,42%,20%);
    transition: var(--transition);
    -webkit-transition: var(--transition);
    -moz-transition: var(--transition);
    -ms-transition: var(--transition);
    -o-transition: var(--transition);
}
.nav__container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nav-bar-cont {
  width: 90%;
}
.nav__logo{
    width: auto;
    height: 2.5rem;
}
.nav__menu{
    display: flex;
    gap: 3rem;
    font-size: 1rem;
    font-weight: 900;
}
ul li a{
  color: rgb(1, 54, 122);
  font-size: 18px;
  font-family: 'Montserrat', sans-serif;
}
ul li a:hover{
  color: rgb(0, 183, 255);
  font-size: 20px;

  
}
#theme__icon{
  background: rgb(0, 57, 126);
    padding: 10px 20px;
    font-weight: bold;
    color: rgb(255, 255, 255);
     cursor: pointer;
     border-radius: 10px;
}
#theme__icon:hover{
  background-color:rgb(9, 33, 68);

}
#theme__icon1{
    background: rgb(0, 57, 126);
    padding: 10px 20px;
    font-weight: bold;
    color: rgb(255, 255, 255);
    border-radius: 10px;


     cursor: pointer;
}
#theme__icon1:hover{
  background-color:rgb(9, 33, 68);

}


.dropdown{
    position: relative;
}
.dropdown-content{
    display: none;
    position: absolute;
    z-index: 1;
}
.dropdown-content a{
    display: block;
    background-color: black;
    width: 3cm;
}
.dropdown-content a:hover{
   background-color: aliceblue;
   color: black;
}
.dropdown:hover .dropdown-content{
    display: block;
}
/* for tablet */
@media screen and (max-width:1024px) {
    .nav__menu{
        display: none;
        
    }
}

.button3d {
    position: relative;
    display: inline-block;
    padding: 10px 20px;
    font-size: 18px;
    font-weight: bold;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
    perspective: 1000px;
  }
  
  .button3d::before,
  .button3d::after {
    content: "";
    position: absolute;
    top: -2px;
    left: -2px;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    border: 2px solid transparent;
    border-radius: 10px;
    pointer-events: none;
    transition: border-color 0.3s;
  }
  
  .button3d::before {
    z-index: 1;
  }
  
  .button3d::after {
    z-index: 2;
    transform: translateZ(-8px);
  }
  
  .button3d:hover::before {
    border-color: #ff1493;
  }
  
  .button3d:hover::after {
    transform: translateZ(0);
    border-color: #ff1493;
  }
  @media screen and (max-width: 768px) {
    .container.mobile-menu-open {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .nav__menu.mobile-menu-visible {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }
  
    .nav__menu.mobile-menu-visible li {
      margin: 10px 0;
    }
  
    .nav__logo.logo-hidden {
      display: none;
    }
  
    .mobile-menu-toggle {
      display: block;
      cursor: pointer;
      font-size: 24px;
      color: white;
      margin-right: 10px;
    }
  }