.ourTrusted-div {
    background: linear-gradient(to top, #f6f6f6, #f4f4f4, #fffcfc);
    margin-top:30px;
    display:flex;
}


.top-cat-icons {
    & img {
filter:brightness(100%) sepia(55%);
border-radius: 10px;
-webkit-box-reflect: below -2px linear-gradient(transparent, transparent, #0003);
    }
}