/* UserLogin.css */

/* Background Styles */
.allcontainer{
  background: url('https://i.pinimg.com/originals/38/e1/0b/38e10b84cd065dd919573722aaf21bdb.jpg') no-repeat center center fixed;
  background-size: cover;
  font-family: 'Montserrat', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
}
.form-group{
  display: flex;
}
.lab{
  font-size: 100%;
}
/* Glassmorphism Card Styles */
.login-container {
  background: rgba(255, 255, 255, 0.2); /* Transparent white background */
  border-radius: 10px;
  padding: 20px;
  width: 100%;
  max-width: 400px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.125);
  text-align: center;
}

.login-form h2 {
  color: #fff; /* White text color */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Text shadow for readability */
}


label {
  font-weight: bold;
  color: #fff; /* White text color */
}

.responsive-input {
  width: 100%;
  padding: 10px;
  border: none;
  border-bottom: 2px solid rgba(255, 255, 255, 0.6); /* White border with opacity */
  background: transparent;
  font-size: 16px;
  color: #fff; /* White text color */
  outline: none;
}

.responsive-input::placeholder {
  color: rgba(255, 255, 255, 0.6); /* Placeholder text color with opacity */
}

.error {
  color: #ff6b6b; /* Red error text color */
  font-size: 14px;
  margin-top: 5px;
  text-align: center;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5); /* Text shadow for readability */
}

.button1 {
  background-color: #0074cc;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  width: 100%;
  max-width: 200px;
  margin: 0 auto;
  transition: background-color 0.3s ease; /* Smooth transition for button color */
}

.button1:hover {
  background-color: #005ba3; /* Darker color on hover */
}
