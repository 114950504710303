.width {
  width: 95%;
  margin: auto;
}
.nav-m-top {
  margin-top: 50px;
}
.frst-nav-m-top {
  margin-top: 7rem;
}
.text-trns-cap {
  text-transform: uppercase;
}

/* ------------------------------------------------------ Notification --------------------------------------------------- */
.past-holiday{
  color: rgb(140, 159, 159);
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
}
.current-holiday{
  color: rgb(42, 45, 212);
  border: navy 1px solid;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
}
.upcoming-holiday{
  color: rgb(44, 28, 58);
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
}
.Notification-btn {
  margin-left: 20px;
  margin-bottom: 5px;
  border-radius: 5px;
}

.All-Notification {
  width: 75%;
  margin: auto;
  display: flex;
  border-radius: 5px;
  background-color: rgb(247, 247, 247);
  padding: 20px;
  cursor: pointer;
  margin-bottom: 10px;
}

/* ------------------------------------------------------ Holidays --------------------------------------------------- */

.holiday-table {
  width: 100%;
  border-collapse: collapse;
  & th,
  td {
    padding: 8px 20px;
    box-shadow: 0px 5px 2px rgba(0, 0, 0, 0.1);
    text-align: left;
    text-transform: capitalize;
    cursor: pointer;
  }
  & td:last-child {
    font-family: 'Montserrat', sans-serif;
  }
  & tr:nth-child(even) {
    background: #f4f4f4;
  }
  & thead {
    background-color: darkblue;
    color: white;
  }
}


.past-holiday {
  background-color: #ffe6e6;
}

.current-holiday {
  background-color: #ffd699;
}

.upcoming-holiday:hover {
  background-color: #e6f7ff;
}
/* ----------------------------------------- Leaves -------------------------------- */

.leave-top-bar {
  background: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 30px;
  box-shadow: 1px 1px 3px gray;
  & button {
    background-color: #092144;
    color: white;
    margin: 0;
    &:hover {
      filter: drop-shadow(0 0 1px black);
      background: #3498DB;
    }
  }
}

.emp-leave-data {
  min-height: 50px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  box-shadow: 1px 1px 3px gray;
  & img {
    border-radius: 50%;
    margin-left: 15px;
  }
  & P {
    margin-left: 15px;
    &:nth-child(2) {
      width: 200px;
    }
    &:nth-child(3) {
      width: 230px;
      text-align: center;
    }
    &:nth-child(4) {
      width: 120px;
    }
  }
}
