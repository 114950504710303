.profile-form{
    border: 1px solid black;
    width: 50%;
    left: 30%;
    padding: 10px;
    border-radius: 20px;
}
.wholeform{
    display: flex;
    justify-content: center;
}

.paginat {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    list-style: none;
    padding: 0;
  }
  
  .paginat li {
    display: inline-block;
    margin: 0 5px;
  }
  @keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes bounceIn {
    from {
        transform: scale(0.8);
    }
    to {
        transform: scale(1);
    }
}

@keyframes fadeInUp {
    from {
        transform: translateY(20px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}
  
  .paginat a {
    color: #007bff;
    text-decoration: none;
    padding: 5px 10px;
    border: 1px solid #007bff;
    border-radius: 4px;
    transition: background-color 0.2s, color 0.2s;
  }
  
  .paginat a.active {
    background-color: #007bff;
    color: #fff;
    cursor: not-allowed;
    pointer-events: none;
  }
  
  .paginat a:hover {
    background-color: #e7e7e7;
  }
  

  /* popup deatails btn css start */
  .popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  .popup-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  }
  .taskbtn2001{
    border-radius: 10px;
    background-color: #092748;
    color: #fff;
    padding:10px 15px;
    transition: all 0.5s;
    margin-left: 50px;
    &:hover {
      background-color: #3498DB;
    }
  }
  .taskbtn2001:hover {
    background-color: #3498DB !important;
  }
  .user-welcome{
    cursor: pointer;
}

  #cars2001{
    border: 2px solid #0b8abc;
    height: 48px;
    width: 200px;
    border-radius: 10px;
  }
  #field1,#field3{
    height: 5px;
    width: 220px;
    margin-left: 10px;
  }
  label::first-letter{
    color: red;
  }
  .taskbtn2002{
    width: 100px;
  }
  /* popup deatails btn css end */
  .input1,.input2{
    width: 30% !important;
    height: 50px !important;
  }
  /* AddAdmin.css */

.features-container {
  text-align: center;
  margin: 160px 50px;
}

.features-heading {
  margin-bottom: 20px;
}

.features-heading h3 {
  font-size: 24px;
  color: #673ab7;
}

.features-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
  padding: 30px;
}

.task-btn {
  padding: 15px 30px;
  font-size: 16px;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  background-color: #2196f3;
  color: #ffffff;
  text-decoration: none;
  transition: background-color 0.3s;
}

.task-btn:hover {
  background-color: #1565c0;
  transform: scale(1.05);
}

