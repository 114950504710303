:root {
  --backgroundColor: rgba(246, 241, 209);
  --colorShadeA: rgb(106, 163, 137);
  --colorShadeB: rgb(121, 186, 156);
  --colorShadeC: rgb(150, 232, 195);
  --colorShadeD: rgb(187, 232, 211);
  --colorShadeE: rgb(205, 255, 232);
  --colorShadeF: rgb(255, 255, 255);
  --colorShadeG: rgb(186, 130, 121);
  --colorShadeH: rgb(151, 0, 0);
  --colorShadeI: rgb(248, 44, 44);
  --colorShadeJ: rgb(242, 10, 6);
}

@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,400i,700");
@media only screen and (max-device-width: 768px) {
  .big-button,
  .big-button1 {
    display: none !important;
  }
}

.big-button {
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  font-size: 1.5rem;
  color: var(--colorShadeA);
  font-weight: 700;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
}

.big-button {
  padding: 0.5em 1em;
  border: 2px solid var(--colorShadeA);
  border-radius: 1em;
  background: var(--colorShadeE);
  transform-style: preserve-3d;
  transition: all 175ms cubic-bezier(0, 0, 1, 1);
}
.big-button::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--colorShadeC);
  border-radius: inherit;
  /* box-shadow: 0 0 0 2px var(--colorShadeB), 0 0.75em 0 0 var(--colorShadeA); */
  transform: translate3d(0, 0.75em, -0.5em);
  transition: all 175ms cubic-bezier(0, 0, 1, 1);
}

.big-button:hover {
  background: var(--colorShadeD);
  transform: translate(0, 0.375em);
}

.big-button:hover::before {
  transform: translate3d(0, 0.75em, -0.5em);
}

.big-button:active {
  transform: translate(0em, 0.75em);
}

.alert-error {
  color: red;
}

.alert-success {
  color: green;
}
.big-button:active::before {
  transform: translate3d(0, 0, -0.5em);

  box-shadow: 0 0 0 2px var(--colorShadeB), 0 0.25em 0 0 var(--colorShadeB);
}
.big-button1 {
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  font-size: 1.5rem;
  color: var(--colorShadeF);
  font-weight: 700;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
}

.big-button1 {
  padding: 0.5em 1em;
  border: 2px solid var(--colorShadeF);
  border-radius: 1em;
  background: var(--colorShadeJ);
  transform-style: preserve-3d;
  transition: all 175ms cubic-bezier(0, 0, 1, 1);
}
.big-button1::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--colorShadeH);
  border-radius: inherit;
  /* box-shadow: 0 0 0 2px var(--colorShadeG), 0 0.75em 0 0 var(--colorShadeF); */
  transform: translate3d(0, 0.75em, -0.5em);
  transition: all 175ms cubic-bezier(0, 0, 1, 1);
}

.big-button1:hover {
  background: var(--colorShadeI);
  /* transform: translate(0, 0.375em); */
}

.big-button1:hover::before {
  transform: translate3d(0, 0.75em, -0.5em);
}

.big-button1:active {
  transform: translate(0em, 0.75em);
}

.big-button1:active::before {
  transform: translate3d(0, 0, -0.5em);

  box-shadow: 0 0 0 2px var(--colorShadeG), 0 0.25em 0 0 var(--colorShadeG);
}
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.blinking {
  animation: blink 1.5s infinite; /* Adjust the animation duration as needed */
}
.modern-color {
  color: #3498db; /* You can change this to your preferred modern color */
}


/* -------------------- tool tip ---------------------------------- */


.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: fit-content;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 15px;

  position: absolute;
  z-index: 1;
  bottom: -35px;
  left: 50%;
  margin-left: -60px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
