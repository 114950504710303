*{
    margin: 0%;
    padding: 0%;
    box-sizing: border-box;
}
.hero{
    height: 45vh;
    background: linear-gradient(90deg,#d6d6c2,#c2dcc922 60%,#d6d6c2);
    display: flex;
    width: 60%;
    margin: auto;
   
}
.circle{
    width: 26%;
    border-radius: 0% 0% 98% 3%;
    display: flex;
    justify-content: center;
    background: linear-gradient(100deg,#04227b,#2b52e2 60%);
    /* background-color:  #0099e6; */
    height: 30vh;
    margin-right: -15%;
}
.circle-last{
    width: 15%;
    border-radius: 98% 3% 0% 0%;
    display: flex;
    justify-content: center;
    background: linear-gradient(140deg,#04227b,#2b52e2 60%, #04227b);
    position: relative;
    top: 20vh;
    height: 25vh;
    
}
.hero-left{
    width: 15%;
    margin-top: -75px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 60vh;
    
}
.hero-img img{
    border-radius: 60%;
    height: 190px;
    width:190px;
    box-shadow: 5px 4px 1px 3px #3538fd;
}
.hero-center{
    width: 16%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 13%;
}
.hero-center h1, u{
   color:  #2b52e2;
   padding-bottom: 8px;
   
}
.hero-center p{
    font-weight: 500;
}
.hero-right{
    width: 16%;
    margin: auto;
}
.hero-right img{
    height: 2.5rem;
    width: auto;
}
