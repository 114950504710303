.about__container{
    display: grid;
    grid-template-columns: 35% 53%;
    gap: 12%;
}
#about{
    background-color: rgb(212, 212, 212);
    padding: 50px;
    margin: 50px 0px;
}
.about__portrait{
    height: fit-content;
    width: auto;
    max-height: 40rem;
    border: 1rem solid var(--color-white);
    transition: var(--transition);
    -webkit-transition: var(--transition);
    -moz-transition: var(--transition);
    -ms-transition: var(--transition);
    -o-transition: var(--transition);
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 1.5rem;
}
.about__portrait:hover{
    border-width: 0;
}
.about__cards{
    margin: 2rem 0 1.5rem;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 1.5rem;
}
.card.about__card{
    text-align: center;
    background-color: rgb(9, 33, 68);
    border: 2px solid white;
    
}
.card.about__card:hover{
    text-align: center;
    background-color: rgb(23, 77, 158);
    border: 2px solid white;
    
}
.about__card-icon{
    font-size: 2rem;
    background-color: rgb(9, 33, 68);
    color: white;
    border-radius: var(--border-radius-3);
    width: fit-content;
    margin-inline: auto;
    padding: 0.6rem;
    display: flex;
    align-items: center;
    transition: var(--transition);
    border: 2px solid white;
}

.card.about__card:hover .about__card-icon{
  background:var( --color-primary);
  color:var(--color-white);
  
}

.card.about__card h5{
    margin: 1rem 0;
 color: white;
}

.about__right p{
    margin-bottom: 0.8rem;
}
.about__right .btn{
    margin-top: 2.5rem;

}
.container .about__container{
    display: flex;
}
@media screen and (max-width:1024px) {
    .about__portrait{
        display:grid;
        width: 5cm;
        height: 7cm;
    }
    .about__container{
        grid-template-columns: 1fr;
    }
    .about__right h2{
        text-align: center;

    }
}

@media screen and(max-width:600px) {
    .about__portrait{
        display:grid;
        width: 5cm;
        height: 7cm;
    }

    .about__cards{
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
    }
    .about__right .btn{
        margin-top: 2rem;

    }
}