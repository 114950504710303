.card{
    background: var(--color-white);
    padding: 1.5rem;
    border: 2px solid transparent;
    border-radius: var(--border-radius-1);
    transition: var(--transition);
    -webkit-border-radius: var(--border-radius-1);
    -moz-border-radius: var(--border-radius-1);
    -ms-border-radius: var(--border-radius-1);
    -o-border-radius: var(--border-radius-1);
    -webkit-transition: var(--transition);
    -moz-transition: var(--transition);
    -ms-transition: var(--transition);
    -o-transition: var(--transition);
}
/* App.css */
.app-container {
    position: relative;
    overflow: hidden;
  }
  @media(max-width:500px){
    .app-container{
      display: none;
    }
    .logby{
      display: none;
    }
  }

  .background-animation {
    width: 100%;
    height: 100vh;
    background: linear-gradient(to bottom, #3498db, #2c3e50);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease;
  }
  
  .background-animation.scrolled {
    background: #2c3e50;
  }
  
  .rope-container {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .rope {
    width: 2px;
    height: 100vh;
    background: #280b0b;
    position: relative;
    margin: 0 10px; /* Adjust spacing between ropes */
  }
  
  .animation-item {
    width: 20px;
    height: 20px;
    background: #ce3030;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: transform 0.5s ease;
  }
  
  .animation-item.animated {
    transform: translate(-50%, -70%);
  }
  
  @keyframes swing {
    0%, 100% {
      transform: translateX(-50%) rotate(0deg);
    }
    50% {
      transform: translateX(-50%) rotate(20deg);
    }
  }
  
  .scroll-down {
    margin-top: 20px;
    cursor: pointer;
    font-size: 18px;
    color: white;
  }
  

.card:hover{
    background: transparent;
    border-color: var(--color-white);
    transform: translateY(-0.5rem);
    -webkit-transform: translateY(-0.5rem);
    -moz-transform: translateY(-0.5rem);
    -ms-transform: translateY(-0.5rem);
    -o-transform: translateY(-0.5rem);
}
.card.light{
    background: var(--color-light);

}

.card.light{
    background-color: var(--color-light);
    background: transparent;
}

@media screen and (max-width:600px) {
    .card{
        padding: 1.2rem;

    }
}