/* .upload-label {
  display: inline-block;
  padding: 10px 15px;
  background-color: #f0f0f0;
  color: #333;
  border-radius: 4px;
  cursor: pointer;
}
ul a button{
  color:  rgb(9, 33, 68);
  font-weight: bold;
}
.uploaded-image {
  display: block;
  margin-top: 10px;
  max-width: 100%;
  height: auto;
}

.uploaded-images-container {
  display: flex;
  flex-wrap: wrap;
}

.uploaded-images-container img {
  max-width: 150px;
  margin: 5px;
}

.footer {
  width: 100%;
  height: auto;
  background-color: rgb(9, 33, 68);
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  gap: 80px;
}

.footer-card1 {
  width: 250px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  margin-top: 20px;
}
#footer-displayline {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #999999;
  display: flex;
  margin: 4px 0px 0px 0px;
}
.card1-ourlinks {
  width: 85%;
  color: rgb(207, 207, 207);
  font-size: 23px;
  font-weight: bolder;
}
.card1-lists {
  margin: 7px 0;
  display: flex;
  flex-direction: column;
  gap: 3px;
}
#card1-lists {
  width: auto;
  font-size: 16px;
  text-decoration: none;
  color: rgb(214, 218, 159);
  font-weight: lighter;
}
#card1-lists:active {
color: rgb(165, 50, 50);
}
#card1-lists:hover {
  font-family: Georgia, 'Times New Roman', Times, serif;
  font-style: oblique;
}


.footer-card2 {
  width: 300px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}
.card2-address {
  width: 85%;
  color: rgb(207, 207, 207);
  font-size: 23px;
  font-weight: bolder;
}
.card2-addressinfo {
  width: auto;
  font-size: 13px;
  text-decoration: none;
  color: rgb(214, 218, 159);
  margin: 0px;
}
#card2-boldtext {
  font-size: 11px;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}
.footer-card3 {
  width: 200px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 7px;
  margin-top: 20px;
}
.card3-links {
  width: 85%;
  color: rgb(207, 207, 207);
  font-size: 23px;
  font-weight: bolder;
}
.card3-hplinks {
  display: flex;
  flex-direction: column;
  gap: 2px; 
  margin: 0px; 
  list-style: none;
  padding: 0px;
}
#card3-hplink {
font-size: 17px;
text-decoration: none;
color: rgb(214, 218, 159);
font-weight: lighter;
font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
#card3-hplink:active {
  font-weight: bolder;
}
#card3-hplink:hover {
  color: rgb(0, 0, 0);
  font-style: oblique;
}

.footer-card4 {
  width: 250px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 7px;
  margin-top: 20px;
}
.card4-links {
  width: 85%;
  color: rgb(207, 207, 207);
  font-size: 23px;

  font-weight: bolder;
}
.card4-allbuttons {
  padding: 0px;
  margin: 0px;
  display: flex;
  flex-direction: row;
  gap: 5px;
}
.card4-button {
  text-decoration: none;
  color: black;
}
#card4-button {
  width: 100%;
  border-radius: 5px;
  border: 0;
  cursor: pointer;
  padding: 3px 5px;
  font-size: 15px;
  background-color: rgb(240, 202, 77);
  font-family: 'Roboto', 'Helvetica Neue', sans-serif;
}
#card4-button:hover {
  background-color:rgb(155, 125, 26); ;
}
#card4-button:active {
  font-weight: bolder;
}


@media (max-width:768px) {
  .footer {
      width: 100%;
      height: auto;
      background-color: rgb(9, 33, 68);
      display: flex;
      flex-direction: row;
      justify-content: center;
      text-align: center;
      gap: 10px;
  }
  
  .footer-card1 {
      width: 200px;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 5px;
      margin-top: 20px;
  }
  #footer-displayline {
      width: 100%;
      text-align: center;
      border-bottom: 1px solid #999999;
      display: flex;
      margin: 4px 0px 0px 0px;
  
  }
  .card1-ourlinks {
      width: 85%;
      color: rgb(207, 207, 207);
      font-size: 15px;
      font-weight: bolder;
  }
  .card1-lists {
      margin: 7px 0;
      display: flex;
      flex-direction: column;
      gap: 3px;
  }
  #card1-lists {
      width: auto;
      font-size: 14px;
      text-decoration: none;
      color: rgb(214, 218, 159);
      font-weight: lighter;
  }
  #card1-lists:active {
  color: rgb(165, 50, 50);
  }
  #card1-lists:hover {
      font-family: Georgia, 'Times New Roman', Times, serif;
      font-style: oblique;
  }
  
  
  .footer-card2 {
      width: 200px;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      margin-top: 20px;
  }
  .card2-address {
      width: 85%;
      color: rgb(207, 207, 207);
      font-size: 15px;
      font-weight: bolder;
  }
  .card2-addressinfo {
      width: auto;
      font-size: 8px;
      text-decoration: none;
      color: rgb(214, 218, 159);
      margin: 0px;
  }
  #card2-boldtext {
      font-size: 9px;
      font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  }
  .footer-card3 {
      width: 200px;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 7px;
      margin-top: 20px;
  }
  .card3-links {
      width: 85%;
      color: rgb(207, 207, 207);
      font-size: 15px;
      font-weight: bolder;
  }
  .card3-hplinks {
      display: flex;
      flex-direction: column;
      gap: 2px; 
      margin: 0px; 
      list-style: none;
      padding: 0px;
  }
  #card3-hplink {
  font-size: 11px;
  text-decoration: none;
  color: rgb(214, 218, 159);
  font-weight: lighter;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  #card3-hplink:active {
      font-weight: bolder;
  }
  #card3-hplink:hover {
      color: rgb(0, 0, 0);
      font-style: oblique;
  }
  
  .footer-card4 {
      width: 200px;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 7px;
      margin-top: 20px;
  }
  .card4-links {
      width: 85%;
      color: rgb(207, 207, 207);
      font-size: 15px;
  
      font-weight: bolder;
  }
  .card4-allbuttons {
      padding: 0px;
      margin: 0px;
      display: flex;
      flex-direction: row;
      gap: 5px;
  }
  .card4-button {
      text-decoration: none;
      color: black;
  }
  #card4-button {
      width: 100%;
      border-radius: 5px;
      border: 0;
      cursor: pointer;
      padding: 3px 5px;
      font-size: 10px;
      background-color: rgb(240, 202, 77);
      font-family: 'Roboto', 'Helvetica Neue', sans-serif;
  }
  #card4-button:hover {
      background-color:rgb(155, 125, 26); ;
  }
  #card4-button:active {
      font-weight: bolder;
  }
}


@media (max-width:450px) {
  .footer {
      width: 450px;
      height: auto;
      background-color: rgb(17, 35, 59);
      display: flex;
      flex-direction: row;
      justify-content: center;
      text-align: center;
      gap: 10px;
  }
  
  .footer-card1 {
      width: 200px;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 5px;
      margin-top: 20px;
  }
  #footer-displayline {
      width: 100%;
      text-align: center;
      border-bottom: 1px solid #999999;
      display: flex;
      margin: 4px 0px 0px 0px;
  
  }
  .card1-ourlinks {
      width: 85%;
      color: rgb(207, 207, 207);
      font-size: 15px;
      font-weight: bolder;
  }
  .card1-lists {
      margin: 7px 0;
      display: flex;
      flex-direction: column;
      gap: 3px;
  }
  #card1-lists {
      width: auto;
      font-size: 14px;
      text-decoration: none;
      color: rgb(214, 218, 159);
      font-weight: lighter;
  }
  #card1-lists:active {
  color: rgb(165, 50, 50);
  }
  #card1-lists:hover {
      font-family: Georgia, 'Times New Roman', Times, serif;
      font-style: oblique;
  }
  
  
  .footer-card2 {
      width: 200px;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      margin-top: 20px;
  }
  .card2-address {
      width: 85%;
      color: rgb(207, 207, 207);
      font-size: 15px;
      font-weight: bolder;
  }
  .card2-addressinfo {
      width: auto;
      font-size: 10px;
      text-decoration: none;
      color: rgb(214, 218, 159);
      margin: 0px;
  }
  #card2-boldtext {
      font-size: 9px;
      font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  }
  .footer-card3 {
      width: 200px;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 7px;
      margin-top: 20px;
  }
  .card3-links {
      width: 85%;
      color: rgb(207, 207, 207);
      font-size: 15px;
      font-weight: bolder;
  }
  .card3-hplinks {
      display: flex;
      flex-direction: column;
      gap: 2px; 
      margin: 0px; 
      list-style: none;
      padding: 0px;
  }
  #card3-hplink {
  font-size: 11px;
  text-decoration: none;
  color: rgb(214, 218, 159);
  font-weight: lighter;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  #card3-hplink:active {
      font-weight: bolder;
  }
  #card3-hplink:hover {
      color: rgb(0, 0, 0);
      font-style: oblique;
  }
  
  .footer-card4 {
      width: 200px;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 7px;
      margin-top: 20px;
  }
  .card4-links {
      width: 85%;
      color: rgb(207, 207, 207);
      font-size: 15px;
  
      font-weight: bolder;
  }
  .card4-allbuttons {
      padding: 0px;
      margin: 0px;
      display: flex;
      flex-direction: row;
      gap: 5px;
  }
  .card4-button {
      text-decoration: none;
      color: black;
  }
  #card4-button {
      width: 100%;
      border-radius: 5px;
      border: 0;
      cursor: pointer;
      padding: 3px 5px;
      font-size: 10px;
      background-color: rgb(240, 202, 77);
      font-family: 'Roboto', 'Helvetica Neue', sans-serif;
  }
  #card4-button:hover {
      background-color:rgb(155, 125, 26); ;
  }
  #card4-button:active {
      font-weight: bolder;
  }
} */



body {
  font-family: 'Montserrat', sans-serif;
}

.footer-main {
  width: 100%;
  background-color: #03203b;
  display: flex;
  justify-content: center;
  padding-top: 2rem;
  padding-bottom: 0.7rem;
  border-top: 1px solid white;
}
.footer-containers {
  width: 90%;
  display: flex;
  justify-content: center;
  flex-direction: row;
}
.footer-container12 {
  width: 50%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: 20%;
}
.footer-container1 {
  width: fit-content;
  display: flex;
  flex-direction: column;
  color: white;
  gap: 7%;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
}
.footer-container1 a {
  color: white;
  text-decoration: none;
  width: 120px;
}
.footer-container1 a:hover {
  color: #3498DB;
  transition: all 0.5s;
  text-shadow: 1px #2d2d2d;
}
.footer-container2 {
  width: fit-content;
  display: flex;
  flex-direction: column;
  color: white;
  gap: 7%;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
}
.footer-container3-first {
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  padding: 0px 15px;
  text-decoration: none;
  color: white;
}
.footer-container3-first-link {
  display: flex;
  flex-direction: row;
  font-size: 90%;
  gap: 2px;
}
.footer-container3-first-envelope {
  margin-right: 12px;
  width: 30px;
  height: 30px;
  border-radius: 4px;
  color: #ffffff;
  background-color: #3498DB;
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer-container3-first-link-number {
  color: white;
  text-decoration: none;
}
.f-c-f-p:hover {
  color:  #3498DB;
}
.footer-container3-second {
  width: 100%;
  height: 25px;
  display: flex;
  align-items: center;
}
.footer-container3-line {
  width: 90%;
  height: 0.5px;
  background-color: white;
  margin: 10px;
  margin-top: 15px;
}
.footer-container3-third {
  width: 100%;
  display: flex;
  flex-direction: row ;
  justify-content: space-evenly;
  font-size: 20px;
}

.footer-container3-third a{
  width: 2rem;
  height: 2rem;
  /* background: linear-gradient(to bottom, #092144, #3498DB 100%); */
  background: #3498DB;
  color: white;
  display: grid;
  place-items: center;
  border-radius: 10px; 
}
.footer-container3-third a:hover{
  background: linear-gradient(to bottom, #092144, #3498DB 100%);
}


.footer-container3-imageLinks {
  width: 15%;
  text-align: center;
}
.f-c-iL {
  color:  #3498DB;
}
.f-c-iL:hover {
  color:  #bebebe;
}
.footer-container3-first-para {
  font-size: 95%;
}




.footer-bottom {
  text-align: center;
  /* background-color: rgb(0, 0, 0, 0.3); */
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10%;
  /* background-color: #092144; */
  position: fixed;
  bottom: -5px;
width: 100%;
}

@media (max-width:768px) {
  .footer-containers {
      width: 95%;
      flex-direction: column;
      padding: 0.5rem 0 0;
  }
  .footer-container12 {
      width: 100%;
      display: flex;
      justify-content: center;
      flex-direction: row;
      border-bottom: 0.2px solid gray;
      overflow: hidden;
  }
  .footer-container1 {
      width: 50%;
      padding: 5% 0 2.5% 2%;
      gap: 7px;
  }
  .footer-container2 {
      width: 50%;
      padding: 5% 0 2.5% 0;
      gap: 7px;
  }
  .footer-container3 {
      width: 100%;
      text-align: left;
  }
  .footer-container3-all {
      width: 100%;
      text-align: left;
      display: flex;
      flex-direction: row;
      overflow: hidden;
      justify-content: center;
      gap: 2%;
  }
  .footer-container3-one {
      width: 80%;
      display: flex;
      flex-direction: column;

  }
  .footer-container3-second {
      display: none;
  }
  .footer-container3-third {
      gap: 5%;
      width: 18%;
      align-items: center;
      justify-content: left;
      display: flex;
      flex-wrap: wrap;
  }
  .footer-container3-imageLinks {
      width: fit-content;
  }
}