.aes-input{
    width: 400px;
    margin-top: unset;
}
.aes-ul {
    position: absolute;
    width: 200px;
    min-height: 10px;
    max-height: 250px;
    overflow-y: auto;
    background-color: white;
    box-shadow: 0px 1px 4px gray;
    margin-top: 5px;
    border-radius: 5px;
    & li{
        padding: 5px 0 5px 10px;
        cursor: pointer;
        &:hover {
            background-color: aliceblue;
        }
    }
}
@media (max-width:1003px) {
    .aes-input{
        width: 250px;
    }
}
@media (max-width:600px) {
    .aes-input{
        display: none;
    }
}




/* ------------------------------------- Allempdash ----------------------------------------------------------------------- */

.Allempdash-div {
    width: 75%;
    margin: auto;
    min-height: 100px;
    margin-top: 100px;
    display: flex;
}

.Allempdash-img-div {
    height: 100%; 
    padding: 10px; 
    text-align: center;
}

.Allempdash-img {
    width: 170px; 
    height: 170px; 
    border-radius: 50%;
}

.icon-style-prof {
    padding: 5px;
    font-size: 25px;
    background: storedColor;
    box-shadow: 0px 1px 3px gray;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    margin-right: 10px;
    &:hover {
        transform: translateY(-3px);
    }
}

.Allempdash-cont-div {
    width: 100%;
    height: 100%; 
    padding: 10px;
}

.Allempdash-c-h3 {
    text-transform: uppercase;
    color: white;
    font-family: sans-serif;
    font-weight: 100;
}

.Allempdash-cont-sub-div {
    width: 90%;
    background: whitesmoke;
    margin-top: 10px;
    & h5 {
        padding: 10px 20px;
        font-weight: 100;
        border-bottom: 1px solid gray;
    }
}

.Allempdash-cont-details-div {
    width: 100%;
    min-height: 70%;
    padding: 20px;
    & span {
        display: flex;
        align-items: center;
        padding-top: 10px;
    }
}
.Allempdash-cont-details-div-flex {
    display: flex;
    flex-wrap: wrap;
    & span {
        width: 31.3%;
        margin: 0 1% 2% 0;
        height: 40px;
        display: unset;
        padding-top: unset;
    }
}