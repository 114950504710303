/* Color.css */

.App123 {
  position: relative;
  display: inline-block;
}

.popup {
  position: absolute;
  top: 50px;
  right: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 15px;
  z-index: 10;
  display: flex;
  flex-direction: column;
}

.color-options {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.color-option {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: bold;
  text-transform: capitalize;
}

.MdColorLens {
  cursor: pointer;
  color: #3498db;
  font-size: 25px;
  margin-right: 10px;
}

.MdColorLens:hover {
  color: #2980b9;
}

/* Add transition for a smoother effect */
.color-option {
  transition: background-color 0.3s ease;
}

.color-option:hover {
  transform: scale(1.6);
  transition: 0.4s;
}
