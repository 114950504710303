header{
    margin-top: 5rem;
    height: calc(100vh - 5rem);
    display: grid;
    place-items: center;
    background: white;
    transition: var(--transition);
    -webkit-transition: var(--transition);
    -moz-transition: var(--transition);
    -ms-transition: var(--transition);
    -o-transition: var(--transition);
}
.header__container{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    
}
.header__profile{
    width: 18rem;
    height: 22rem;
    border-radius: 9rem 9rem 2rem 2rem;
    overflow: hidden;
    margin-bottom: 1.5rem;
    -webkit-border-radius: 9rem 9rem 2rem 2rem;
    -moz-border-radius: 9rem 9rem 2rem 2rem;
    -ms-border-radius: 9rem 9rem 2rem 2rem;
    -o-border-radius: 9rem 9rem 2rem 2rem;
}
.header__container p{
    width: 35rem;
    text-align: center;
    margin: 0.6rem 0.2rem;
}
.header__cta{
    display: flex;
    align-items: center;
    gap: 1.2rem;
}
.header__socials{
 font-size: 26px;
    position: absolute;
    left:0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    gap: 1rem;

}
.header__socials a{
    width: 2.5rem;
    height: 2.5rem;
    aspect-ratio: 1/1;
    background: #092144;
    color: white;
    display: grid;
    place-items: center;
    border-radius: var(--border-radius-3);

   
}
.header__socials a:hover{
    background: var(--color-primary);
    color: var(--color-white);
    transform: translateX(-0.5rem);
    -webkit-transform: translateX(-0.5rem);
    -moz-transform: translateX(-0.5rem);
    -ms-transform: translateX(-0.5rem);
    -o-transform: translateX(-0.5rem);
}

@media screen and (max-width:1024px) {
    header{
        margin-top: 0;
        height: 100vh;

    }
}

@media screen and (max-width:600px) {
    .header__profile{
        width: 14rem;
        height: 17rem;
        max-width: 100%;
        max-height: 35vh;

    }
    .header__container p{
        width: 100%;
    }
    .header__cta{
        gap: 1rem;
    }
    .header__socials{
        display: none;
    }
}
.btn{
    background-color: #092144;
    color: white;
    font-weight: bold;
}
.btn:hover{
    background-color: #092144;
    font-weight: bold;
    color: white;
}
