/* Add this CSS for styling the cards */
.app123 {
    text-align: center;
    padding: 20px;
  }
  
  .user-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
  }
  
  .user-card {
    background-color: #f5f5f5;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
  
    &:hover {
      transform: scale(1.05);
    }
  }
  
  .user-image {
    border-radius: 50%;
    height: 100px;
    width: 100px;
    margin-bottom: 10px;
  }
  
  .user-details {
    text-align: left;
  }
  
  .edit-modal {
    /* Add styles for the edit modal if needed */
  }
  
  /* Add more styles as needed */
  