.services__container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem 2rem ;
}
.service__light{
    background-color: rgb(255, 255, 255);
    border: 2px solid rgb(0, 106, 133);
}
.service__light:hover{
    background-color: whitesmoke;
    border: 2px solid gray;
    color: gray;
}
#services{
    padding: 50px;
    background-color: white;
    margin: -20px 0px;
}
.card.service{
    display: flex;
    align-items: flex-start;
    gap: 1rem;

}
.service__icon{
    background: rgb(9, 33, 68);
    padding-left: 10px;
    padding-top: 6px;
    border-radius:var(--border-radius-3);
    font-size: 2rem;
    color: rgb(255, 230, 0);
    transition: var(--transition);

  
}

.service:hover{
    background: var(--color-light);
    color: var(--color-primary);
}



@media screen and (max-width:1024px) {
    .services__container{
        gap: 1.5rem;
    }
}
@media screen and (max-width:600px) {
    .services__container{
        grid-template-columns: 1fr;
        gap:1.2rem;
    }
    .card.service{
        gap: 1.2rem;
    }
}