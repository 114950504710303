/* PaySlip.css */
.company-logo {
  max-width: 100px;
  float: left; /* Adjust the logo size as needed */
}

.earnings,
.deductions,
.net-salary,
.stamp {
  margin-top: 20px;
}

/* PaySlip.css */
/* Add or modify styles as needed */

.employee-list-container {
  width: 850px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 70px;
  padding: 25px;
  box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.1);
}

.filter-section {
  display: flex;
  flex-direction: column;
}

.input-filter {
  margin-right: 10px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.employee-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.employee-card {
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 10px;
  padding: 20px;
  border-radius: 8px;
  transition: box-shadow 0.3s ease;
  border: navy 1px solid;

  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border: navy;
  }

  h3 {
    margin-bottom: 8px;
    color: #333;
  }

  p {
    color: #666;
  }
}

.employee-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}

.edit-button,
.print-button {
  background-color: #4caf50;
  color: #fff;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #45a049;
  }
}


.employee-list {
  display: flex;
  flex-wrap: wrap;
}

.pay-slip {
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  .header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .details {
    margin-bottom: 20px;
  }
  
  .print-button {
    background-color: #4caf50;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
  }
  .print-button12 {
    background-color: #092748;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
  }
  
  @media print {
    body * {
      visibility: hidden;
    }
    table *{
      visibility: visible;
    }
    .pay-slip,
    .pay-slip * {
      visibility: visible;
    }
    .pay-slip {
      position: absolute;
      left: 0;
      top: 0;
    }
    .print-button {
      display: none;
    }
    table {
      width: 100%;
      border-collapse: collapse;
      border: 1px solid black;
    }
    table td,
    table th {
      border: 1px solid black;
    }
    table td {
      line-height: 25px;
      padding-left: 15px;
    }
    table th {
      background-color: #fbc403;
      color: #363636;
    }
  }
  