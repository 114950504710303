/* NewProjectDetails.css */

.project-details-container {
   display: flex;
   justify-content: center;
   align-items: center;
   min-height: 100vh;
   background: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)),
     url('https://www.pixelstalk.net/wp-content/uploads/2016/07/Laptop-Pictures-HD.jpg') center/cover no-repeat;
   background-blur: 10px; /* Apply a blur effect */
 }
 
 .main-content {
   max-width: 800px;
   width: 90%;
   padding: 20px;
   background: rgba(255, 255, 255, 0.2); /* Apply a semi-transparent background */
   border-radius: 10px;
   box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
 }
 .survey-form{
  margin: auto;
  width: 100%;
 }
 
 .glassmorphism-background {
   background:white;
   color: black; /* Apply a glassmorphism background */
   backdrop-filter: blur(10px); /* Apply a blur effect to the background */
   padding: 20px;
   border-radius: 10px;
   box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
 }
 .radio-btn-label {
   display: flex;
   justify-content:center;
   /* margin-bottom: 10px; */
 }
.maindi{
  display: flex;
  justify-content: space-between;
}

 /* dashboradIcon2001 css start */
  .dashboradIcon2001{
    display: flex;
    justify-content: space-between;
  }
  button.submitbtn2001{
    background-color:#090741; /* Green */
    border: 1px solid #090741;
    color: #fff;
    padding: 16px 32px;
    text-align: center;
    text-decoration: none;
    display: flex;
    font-size: 16px;
    margin: 4px 2px;
    transition-duration: 0.4s;
    cursor: pointer;
    width: 150px;
    border-radius: 25px;
    justify-content: center;
  }

  .submitbtn2001:active {
    background-color: #4CAF50;
    color: white;
    border: 1px solid #4CAF50;
  }
 /* dashboradIcon2001 css end */


 .radio-btn-label input[type="radio"] {
   margin: 0 10px; 
   /* Reduce the margin-right to your desired spacing */
 }
 label{
   color: black;
 }
 .required{
   color: red;
   font-size: 20px;
 }
 .heading{
  border: solid 2px var(--color-primary);
  border-radius: 20px;
  padding: 20px;
 }
 
 /* Add responsive styles as needed */
 @media (max-width: 768px) {
   .main-content {
     width: 100%;
     padding: 10px;
   }
 }
 .loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(168, 167, 167, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.loading-spinner {
  border: 6px solid rgba(255, 255, 255, 0.3);
  border-top: 6px solid #db3434;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
