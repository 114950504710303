.mainHome-div {
  width: 90%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  gap: 70px 30px;
  padding: 50px 0;
}
@media (max-width: 1091px) {
  .mainHome-div {
    gap: 50px 30px;
  }
}

.background-container {
  height: 15rem;
  width: 100%;
  background: #092144;
  position: absolute;
  top: 0;
  z-index: -1;
}

.mainHOME-INST {
  width: 90%;
  display: flex;
  align-items: center;
  margin: 60px auto;
  gap: 2.6%;
}
@media (max-width : 768px) {
  .mainHOME-INST {
    flex-direction: column;
    margin-top: 30px;
  }
}

/* ------------------------------ HomeHeader ----------------------------------------- */

.homeHeaderContainer {
  display: flex;
  width: 70%;
  margin: 0 auto;
  height: 350px;
  background: white;
  box-shadow: 0 0.1px 5px rgba(0, 0, 0, 0.15);
  /* background: linear-gradient(to right, transparent, lightgray); */
}

@media (max-width: 1091px) {
  .homeHeaderContainer {
    width: 100%;
  }
}

@media (max-width: 704px) {
  .homeHeaderContainer {
    height: auto;
  }
}

.headerLeftSection {
  width: 60%;
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.headerTitle {
  color: #092144;
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 20px;
}

.headerParagraph {
  font-size: 16px;
  color: black;
  margin-bottom: 50px;
}

.headerRightSection {
  width: clamp(300px, 40%, 500px);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.headerImage {
  border-radius: 30px 0;
  box-shadow: 0px 1px 2px gray;
  width: 95%;
  height: 97%;
}

.headerSocials {
  position: relative;
  width: 0;
  margin-left: -70px;
  margin-bottom: 20px;
}

.headerSocialLink {
  margin-left: 20px;
}

/* -------------------- Notification-Home-Card --------------------------------- */
.mainHome-sub-div {
  width: 25%;
  height: auto;
  background: white;
  padding: 10px 30px;
  overflow: "hidden";
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
  font-family: 'Montserrat', sans-serif;
}

@media (max-width: 1091px) {
  .mainHome-sub-div {
    width: 100%;
  }
}
.Notification-Home-Card {
  padding: 5px 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: black;
  &:hover {
    transform: translateY(-0.5rem);
  }
}

.notification-container {
  padding: 15px 10px;
  font-family: 'Montserrat', sans-serif;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.notification-title {
  font-size: 18px;
}

.notification-icon {
  color: darkred;
}

.notification-card {
  display: flex;
  align-items: center;
}

.notification-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.notification-content {
  margin-left: 10px;
  line-height: 20px;
}

.notification-title-bold {
  font-weight: bold;
}

.notification-para {
  font-size: 90%;
}

.notification-time {
  font-size: 65%;
}

/* ----------------------- Events-Home-Card ------------------------------------ */

.event-sub-div {
  min-width: 100%;
  background: #092144;
}

.event-sub-div h4 {
  padding: 20px 30px;
  color: whitesmoke;
  font-family: 'Montserrat', sans-serif;
}

.event-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.event-Home-Card {
  width: 30%;
  text-decoration: none;
  padding: 10px 10px;
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  color: black;
  border-radius: 20px;
  &:hover {
    transform: translateY(-0.5rem);
  }
}
@media (max-width: 1091px) {
  .event-sub-div {
    width: 30%;
  }
  .event-row {
    flex-direction: column;
  }
  .event-Home-Card {
    width: 100%;
  }
}

.event-date-info {
  width: 50px;
  height: 50px;
  box-shadow: 0px 1px 2px gray;
  text-align: center;
  line-height: 10px;
  padding: 10px;
  background: lightgray;
}

.event-date-info h3 {
  color: darkred;
}

.event-details {
  margin-left: 30px;
}

.event-details b {
  text-transform: uppercase;
  color: lightcoral;
}

.event-details p {
  font-size: 70%;
  color: rgb(207, 233, 255);
}

.event-view-calendar {
  padding: 5px 10px;
  color: rgb(255, 255, 255);
}

/* -------------------------- Emp of Month ------------------------------------ */


.emp-loading {
  min-width: 150px;
  width: 25%;
  height: 150px;
  margin-top: 15%;
}

.emp-card {
  min-width: 350px;
  width: 30%;
  height: 300px;
  border-radius: 3px;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.15);
}

.emp-card-header {
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;
  height: 30px;
}
.emp-card-header-sub {
  & span {
    font-size: 80%;
    padding: 5px 10px;
    cursor: pointer;
    &:nth-child(1){
      clip-path: polygon(0 0, 95% 0, 100% 100%, 0 100%);
      background-color: rgb(242, 248, 255);
      border-radius: 5px 0 0 5px;
      margin-right: -5px;
    }
    &:nth-child(2) {
      clip-path: polygon(0 0, 95% 0, 100% 100%, 5.5% 100%);
      background-color: #dfdfdf;
      margin-right: -5px;
    }
    &:nth-child(3) {
      clip-path: polygon(0 0, 100% 0, 100% 100%, 9% 100%);
      background-color: rgb(242, 248, 255);
      border-radius: 0 5px 5px 0;
    }
  }
}

.emp-card-title {
  font-size: 20px;
  color: #34495e;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
  line-height: 30px;
  margin-top: 15px;
}
.emp-card-body {
  display: flex;
  width: 100%;
  margin-bottom: 10px;
  text-align: center;
  font-size: 85%;
  color: #2c3e50;
  display: flex;
  justify-content: center;
}

.emp-card-subtitle {
  font-size: 20px;
  color: #7f8c8d;
  font-style: italic;
  cursor: pointer;
  display: flex;
  height: 100%;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  & span {
    color: darkred;
    white-space: noWrap;
    filter: drop-shadow(1px 2px gray);
  }
}

.emp-week-card {
  width:100%;
  height:100px;
  margin-top:-60px;
  display: flex;
  justify-content: space-between;
}

.emp-week-title {
  font-size: 120%;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
  line-height: 30px;
  margin-top: 15px;
  color: transparent;
  -webkit-text-stroke: 0.5px whitesmoke;
}

.emp-bday-heading {
  font-family: monospace;
  color: transparent ;
  margin-top:4%;
  padding-right: 5%;
  border-Top: 0.1px dotted gray;
  text-align: right;
}
.emp-card-image {
  width: 150px;
  height: 160px;
  margin-top: -22px;
  border-radius: 3px;
  padding: 5px;
  box-shadow: 0px -0.5px 5px gray inset;
}

.emp-birthday-card {
  width:100%;
  height:60%;
  background: url("https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRxr3pbhS_QgZP3KjH2e_3H9c6XVgiVfymLMA&usqp=CAU") center no-repeat;
  background-size: 100% 90%;
}
.emp-card-bday-image {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 160px;
  & img {
    transform: skew(5deg);
    box-shadow: 0px 0.1px 3px rgb(76, 164, 194) inset;
    padding: 5px;
    width: 150px;
    height: 100%;
    margin-top: -15px;
  }
  & b {
    filter: drop-shadow(1px 2px gray);
    color: red;
    margin-left: 5%;
  }
}

@media (max-width: 768px) {
  .emp-card {
    width: 80%;
    margin: auto;
    min-height: 250px;
    margin-bottom: 70px;
  }
}

/*----------------------------- InstructionHome.css --------------------------------------------- */

.instruction-container {
  width: 370px;
  width: 30%;
  height: 300px;
  overflow: hidden;
  box-shadow: 0 0.1px 5px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  &:hover {
    transform: translateY(-0.5rem);
  }
}

.instruction-title {
  padding: 15px 25px;
}

.instruction-description {
  padding: 0 20px 15px;
}

.instruction-image {
  width: 100%;
}
@media (max-width: 768px) {
  .instruction-container {
    width: 100%;
    height: 250px;
    margin-bottom: 70px;
  }
}

/*----------------------------------- NameHome.css ------------------------------------------------ */

.name-home-container {
  width: 85%;
  margin: 100px auto 0;
  display: flex;
  justify-content: space-between;
}

.name-home-welcome {
  color: rgba(250, 250, 250, 0.5);
}

.name-home-span {
  color: lightcoral;
}

.name-home-profile {
  color: whitesmoke;
  cursor: pointer;
  display: flex;
  align-items: center;
  &:hover {
    .fa-right-icon {
      transform: translateX(0.1rem);
    }
  }
}

.name-home-loading {
  width: 23px;
  height: 23px;
  border-radius: 50%;
}

/*------------------------------------------------------ NewsfeedHome.css -------------------------------------------- */

.newsfeed-container {
  width: 67.5%;
  height: 300px;
  overflow: hidden;
  box-shadow: 0 0.1px 5px rgba(0, 0, 0, 0.15);
  align-content: center;
  padding: 10px 0 0px 20px;
}

.newsfeed-title {
  padding: 7px 25px 0px;
}

.newsfeed-textarea {
  width: 95%;
  min-height: 35px;
  padding: 0;
  margin-left: 2.5%;
}

.newsfeed-post-button {
  width: 93%;
  margin-left: 5%;
  display: flex;
  justify-content: flex-end;
  padding-bottom: 10px;
}
.newsfeed-post-buttonM {
  background: #092144;
  color: white;
  padding: 6px 25px;
  position: relative;
  margin-top: -40px;
  width: fit-content;
  height: fit-content;
  &:hover {
    transform: translateY(-3px);
    background: #3498db;
  }
}

.newsfeed-message {
  padding: 7px;
  display: flex;
  cursor: pointer;
  /* &:hover {
    transform: translateY(-0.5rem);
  } */
}

.newsfeed-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.newsfeed-message-content {
  margin-left: 10px;
  width: 100%;
}

.newsfeed-message-header {
  display: flex;
  justify-content: space-between;
  color: darkred;
}

.newsfeed-message-name {
  font-weight: bold;
}

.newsfeed-message-time {
  font-size: 70%;
  color: dimgray;
}

.newsfeed-message-body {
  line-height: 14px;
  font-size: 80%;
}

@media (max-width: 768px) {
  .newsfeed-container {
    width: 100%;
  }
}


.uploadicon {
  margin-top: -5%;
  margin-right: 20px;
  background: none;
}

.uploadicon2001 {
  font-size: 22px;
  color:rgb(8,33,68);
  cursor: pointer;
}
.uploadicon2001:hover{
  color: rgb(116, 164, 231);
}




/* ---------------------------------------------------------- Features ------------------------------------------- */


.FeatureHome-div {
  width: 100%;
  height: 170px;
  position: absolute;
  z-index: -1;
  margin-top: 170px;
}

.Featuresdiv-ov {
  width: 90%;
  height: auto;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(20, 1fr);
  overflow-x: scroll;
  padding: 100px 0;
}

.Features-card {
  width:235px;
  height: 330px;
  background: white;
  box-shadow: 0px 1px 5px gray;
  margin-right: 18px;
  &:first-child {
    margin-left: 5px;
  }
  &:last-child {
    margin-right: 5px;
  }
}