/* Chatboard.css */

/* LeftSideDashboard styles */
.left-side-dashboard {
    width: 230px;
    padding: 0 5px 10px 0;
    margin-top: 10px;
    box-shadow: 0px 0.2px 3px gray;
  } 
  .left-side-dashboard-h4 {
    color: white;
    height: 40px;
    display: flex;
    align-items: center;
    & input {
        height: 75%;
        margin: 0;
    }
  }
  
  .left-side-dashboard-ul {
    height: 70vh;
    overflow-y: scroll;
    margin-top: 10px;
    padding-left: 5px;
  }
  
  .left-side-dashboard-li {
    color: rgb(107, 107, 107);
    padding-top: 7px;
    display: flex;
    align-items: center;
    overflow-x: hidden;
    cursor: pointer;
    margin-bottom: 5px;
    &:hover {
        background-color: whitesmoke;
    }
  }
  
  .left-side-dashboard-img {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin-right: 5px;
  }
  
  /* Chatboard styles */
  .chatboard {
    height: 100%;
    display: flex;
    width: 100%;
  }
  
  .chatboard-container {
    width: 100%;
    min-height: 70vh;
    margin-top: 10px;
    box-shadow: 0px 0.2px 3px gray;
    background: whitesmoke;
  }
  
  .chat-header {
    text-align: left;
    color: white;
    height: 40px;
    display: flex;
    align-items: center;
  }

  .chat-Link{
    display: flex;
    align-items: center;
  }

  .receiver-avatar {
    margin:  0 12px;
    width:  30px;
    height:  30px;
    border-radius:  50%;
  }

  .chat-messages {
    padding: 10px 5px 10px 0;
    width: 100%;
    height: 62vh;
    overflow-y: scroll;
    background: white;
  }
  
  .message-container {
    padding: 5px;
    display: flex;
    align-items: start;
  }
  
  .message-container-img {
    margin: 0 12px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
  
  .message-text {
    padding: 8px;
    border-radius: 8px;
    max-width: 70%;
  }
  
  .input-container {
    width: 90%;
    margin: 13px auto auto;
  }
  .send-button {
    padding: 5px 15px;
    border-radius: 5px;
    margin: -35px 0 0 0;
    color: white;
  }
  