/* MobileErrorPage.css */

.mobile-error-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    padding: 20px;
  }
  
  .mobile-icon {
    font-size: 4em;
    color: #ff4500; /* Adjust the color as needed */
  }
  
  .soryyclass{
    font-size: 2em;
    margin: 10px 0;
  }
  
  .sorrypara {
    font-size: 1.2em;
    margin: 5px 0;
  }
  