.custom-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  .custom-table th,
  .custom-table td {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }
  
  .custom-table th {
    background-color: #f2f2f2;
  }
  
  .custom-table tbody tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .custom-table tbody tr:hover {
    background-color: #e9e9e9;
  }
  
  .custom-table button {
    background-color: #dc3545;
    color: white;
    border: none;
    padding: 6px 12px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .custom-table button:hover {
    background-color: #c82333;
  }
  

  .adduserSubmit-sty{
    padding: 10px;
    font-weight: bolder;
cursor: pointer;
color: white;
background-color: rgb(9, 33, 68);
transition: background-color 0.3s ease;
width: 50%;
/* margin-left: 25%; */
display: block; /* Ensure the button takes up the full width */
    margin: 0 auto; /* Center the button horizontally */
position: center;
border-radius: 12px;
  }
  
  .adduserSubmit-sty:hover{
    background-color: rgb(20, 122, 223);
    box-shadow: 0 0 10px rgba(20, 122, 223, 0.5); 
  }
  /* .adduserCheckbox-TL,
  .adduserCheckbox-TS,
  .adduserCheckbox-TST,
  .adduserCheckbox-BD,
  .adduserCheckbox-BDT{
    display: flex;
    flex-direction: column; 
  }
  
  .adduserCheckbox-TL input,
  .adduserCheckbox-TS input,
  .adduserCheckbox-TST input,
  .adduserCheckbox-BD input,
  .adduserCheckbox-BDT input
{
    display: flex;
    align-items: center; 
  } */


  .adduser-TL-LA,
  .adduser-TS-LA,
  .adduser-TST-LA,
  .adduser-BD-LA,
  .adduser-BDT-LA {
    display: flex;
    align-items: center; /* Vertically center the label and checkbox */
    justify-content: space-between; 
    margin-bottom: 10px; /* Add some spacing between each label-checkbox pair */
  }
  
  .adduserCheckbox-TL,
  .adduserCheckbox-TS,
  .adduserCheckbox-TST,
  .adduserCheckbox-BD,
  .adduserCheckbox-BDT {
    display: flex;
    align-items: center; /* Vertically center the checkbox */
  }
  
  .adduserCheckbox-TL input,
  .adduserCheckbox-TS input,
  .adduserCheckbox-TST input,
  .adduserCheckbox-BD input,
  .adduserCheckbox-BDT input {
    margin-left: 1px; 
    margin-right: 30px;
  }
  