.containerem {
  margin-top: 90px;
  border: 1px solid navy;
  border-radius: 20px;
}

.card {
  cursor: pointer;
}

.mail-icon {
  text-align: center;
  font-size: 160%;
}

.badge {
  margin-right: 2px;
}

.btn-close {
  cursor: pointer;
}

.btn-group {
  margin-bottom: 10px;
}

.btn-group button {
  margin-right: 5px;
}
.row21 {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 15px;
}