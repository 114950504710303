.profile-card-main-div {
  width: 100%;
  /* display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px; */
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
}

.company-card {
  min-width: 400px;
  height: 200px;
  display: flex;
  box-shadow: 0px 1px 7px lightgray;
  border-radius: 10px;
  background: rgb(43, 141, 206);
  margin-bottom: 20px;
  cursor: pointer;
}

.company-card-img-div {
  /* width: 170px; */
  height: 100%;
}

.company-card-img {
  width: 100%;
  height: 100%;
}

.company-card-img-sub-div {
  width: 99.9%;
  line-height: 17px;
  overflow: hidden;
  height: fit-content;
  color: wheat;
  position: relative;
  margin-top: -26.5%;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 0 0 0 10px;
  padding: 2px 5px;
  & b {
    white-space: nowrap;
  }
}

.company-card-img-sub-div-cont {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 0;
  & i {
    white-space: nowrap;
  }
}

.company-card-details {
  width: 70%;
  height: 100%;
  padding: 10px;
  /* background: rgba(0, 0, 0, 0.2); */
  border-radius: 7px 7px 7px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: min-width 1.5s ease;
}

.company-card-details-divs {
  & div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    white-space: nowrap;
    overflow: hidden;
    & i:first-child {
      /* display: none; */
    }
  }
}


/* 
.company-card:hover {
  & .company-card-details-divs > div > i:first-child {
    display: flex;
  }
    & .company-card-details {
      min-width: 300px;
      display: flex;
  }
  & .company-card-details-button {
    text-align: end;
  }
}

.company-card:not(:hover) {
  width: fit-content;
} */

/* .profile-card-main-div:hover .company-card:not(:hover) {
  opacity: 0.9;
} */
